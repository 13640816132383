import "./empty-ticket-view.css";

const EmptyTicketView = () => {
  return (
    <div className="empty-ticket-view">
      <h2>No items</h2>
      <p>Items purchased will appear here in your Dashboard</p>
    </div>
  );
};

export { EmptyTicketView };
