import { useUserData } from "../../../hooks/use-user-data";
import "./hero_section.css";
import { EVENT_MODAL_ID } from "../../../consts/consts";
import RWVideo from "../../../assets/images/rw_video.mp4";
import { useEffect, useState } from "react";

const HeroSection = () => {
  const { login, isConnected } = useUserData();
  const [isMuted, setIsMuted] = useState(true);

  const [hasTriggered, setHasTriggered] = useState(false);

  const handleScroll = () => {
    const { scrollY } = window;
    if (scrollY > 0) {
      setHasTriggered(true);
    } else {
      setHasTriggered(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const button = isConnected ? (
    <button
      className="homeButton justify-content-center align-items-center"
      id={EVENT_MODAL_ID}
    >
      BOOK TICKETS
    </button>
  ) : (
    <button
      className="homeButton justify-content-center align-items-center"
      onClick={() => login({ routeToTicketsOnSuccess: false })}
    >
      BOOK TICKETS
    </button>
  );

  return (
    <div className="hero">
      <video
        onClick={() => setIsMuted(!isMuted)}
        className="hero-vid"
        src={RWVideo}
        muted={isMuted}
        loop
        controls={false}
        playsInline
        autoPlay
        style={{
          width: hasTriggered ? "80%" : "100%",
          height: hasTriggered ? "80%" : "100%",
        }}
      >
        <div className="bg"></div>
        <div className="banner-content">
          <div className="container d-flex justify-content-center">
            {button}
          </div>
        </div>
      </video>
    </div>
  );
};

export { HeroSection };
