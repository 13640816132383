import { Ticket, TicketStatus } from "../../../../models/ticket";
import {
  ActiveTicket,
  ActiveTicketType,
} from "../../../../hooks/use-my-ticket-data";

type Props = {
  ticket: Ticket;
  onClaimClick: (activeTicket: ActiveTicket) => void;
  onTransferClick: (activeTicket: ActiveTicket) => void;
};
const TicketDetailsCell = ({
  ticket,
  onClaimClick,
  onTransferClick,
}: Props) => {
  const renderAction = () => {
    switch (ticket.status) {
      case TicketStatus.unclaimed:
        return (
          <button
            className="claim-button"
            onClick={() =>
              onClaimClick({
                ticketId: ticket.id,
                type: ActiveTicketType.claim,
              })
            }
          >
            Claim
          </button>
        );
      default:
        return null;
    }
  };

  const renderTxHash = () => {
    switch (ticket.status) {
      case TicketStatus.claimed:
        return ticket.mintingInformation.transactionHash;
      case TicketStatus.pendingClaimed:
        return "Processing...";
      default:
        return "Only for claimed tickets";
    }
  };

  const renderTransfer = () => {
    switch (ticket.status) {
      case TicketStatus.unclaimed:
        return (
          <button
            className="transfer-button"
            onClick={() =>
              onTransferClick({
                ticketId: ticket.id,
                type: ActiveTicketType.transfer,
              })
            }
          >
            Transfer
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <tr key={`ticket-detail-cell-${ticket.id}`} className="ticket-listing-row">
      <td style={{ paddingTop: "12px" }}>{ticket.id}</td>
      <td style={{ paddingTop: "12px" }}>{ticket.status}</td>
      <td style={{ paddingTop: "12px" }}>{"ERC1155"}</td>
      <td style={{ paddingTop: "12px" }}>{renderTxHash()}</td>
      <td style={{ textAlign: "center" }}>{renderTransfer()}</td>
      <td style={{ textAlign: "center" }}>{renderAction()}</td>
    </tr>
  );
};

export { TicketDetailsCell };
