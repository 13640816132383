import { useEffect, useState } from "react";
import "./email-modal.css";
import { RemoteManagementUseCase } from "../../use-case/remote-management-use-case";
import { Oval } from "react-loader-spinner";
import { BaseModal } from "../base-modal";
import { useUserData } from "../../hooks/use-user-data";
import validator from "email-validator";
class EmailModalConsts {
  static successTimeoutMs = 3000;
}

type TextInputEvent = React.FormEvent<HTMLInputElement>;

type Props = {
  activeTicketId: string;
  onCancelClick: () => void;
};

enum EmailModalError {
  email,
  transfer,
}

const EmailModal = ({ onCancelClick, activeTicketId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState<EmailModalError | undefined>();
  const [email, setEmail] = useState("");
  const [transferSuccess, setTransferSuccess] = useState(false);
  const { getUserTicketInfo } = useUserData();

  useEffect(() => {
    if (transferSuccess) {
      setTimeout(() => {
        getUserTicketInfo();
        onCancelClick();
      }, EmailModalConsts.successTimeoutMs);
    }
  }, [transferSuccess, onCancelClick]);

  const onTransferButtonClick = async () => {
    const trimmedEmail = email.trim();
    const emailIsValid = validator.validate(trimmedEmail);

    if (!emailIsValid) {
      setHasError(EmailModalError.email);
      return;
    }

    setHasError(undefined);
    if (activeTicketId) setIsLoading(true);
    const response = await RemoteManagementUseCase.transferTicket(
      activeTicketId,
      email
    );
    setIsLoading(false);
    if (!response.ok) {
      setHasError(EmailModalError.transfer);
      return;
    }

    setTransferSuccess(true);
  };

  const onInputChange = (event: TextInputEvent) => {
    setHasError(undefined);
    const value = event.currentTarget.value;
    setEmail(value);
  };

  const renderFooter = () => {
    if (isLoading) {
      return (
        <div className="loading-container">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#000000"
            strokeWidth={3}
            secondaryColor="#000000"
          />
        </div>
      );
    }
    if (transferSuccess) {
      return (
        <div className="success-container">
          <p>Your ticket has been successfully transferred</p>
        </div>
      );
    }
    return (
      <div className="button-container">
        <button onClick={onTransferButtonClick}>Yes, confirm</button>
        <button className="cancel-button" onClick={onCancelClick}>
          No, cancel
        </button>
      </div>
    );
  };

  return (
    <BaseModal
      title="Enter email address to confirm ticket transfer."
      onCancelClick={onCancelClick}
      hideClose={isLoading || transferSuccess}
    >
      <p className="email-address">Email address</p>
      <input className="email-input" onChange={onInputChange} value={email} />
      {hasError === EmailModalError.email && (
        <p className="email-error">Please enter a valid email</p>
      )}

      {hasError === EmailModalError.transfer && (
        <p className="email-error">
          There was an error transferring this ticket. Please try again.
          <br /> If the problem persists please contact us.
        </p>
      )}
      <div className="button-container">{renderFooter()}</div>
    </BaseModal>
  );
};

export { EmailModal };
