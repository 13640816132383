import "./digital_spectacle_section.css";
import ThirdBg from "../../../assets/images/thirdBg.png";
import ThirdBgH5 from "../../../assets/images/thirdBg_h5.png";

const DigitalSpectacleSection = () => {
  return (
    <div className="digital-spectacle">
      <div className="container">
        <p
          className="p1 text-large180"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          LIGHTCYCLE
        </p>
      </div>
      <div className="bgImg bgImg_web">
        <img src={ThirdBg} alt="" />
      </div>
      <div className="bgImg bgImg_h5">
        <img src={ThirdBgH5} alt="" />
      </div>

      <div className="" data-aos="fade-left" data-aos-duration="1000">
        <p className="p2">
          LightCycle is a stunning new 3D environment using the latest cutting
          edge technology.
        </p>
      </div>
    </div>
  );
};

export { DigitalSpectacleSection };
