import * as Sentry from "@sentry/react";

class ErrorTracking {
  static handleInfoBreadcrumb = (message: string) => {
    console.info(`Breadcrumb - ${message}`);
    Sentry.addBreadcrumb({
      type: "info",
      level: "info",
      message,
    });
  };

  static handleError = (error: any) => {
    console.error(`Error - ${error}`);
    Sentry.captureException(error);
  };
}
export { ErrorTracking };
