import {
  TicketStatus,
  TicketTier,
  TicketTransactionStatus,
} from "../models/ticket";

export const mapTicketStatusResponse = (ticketStatus: string): TicketStatus => {
  switch (ticketStatus) {
    case "CLAIMED":
      return TicketStatus.claimed;
    case "PENDING_CLAIMED":
      return TicketStatus.pendingClaimed;
    default:
      return TicketStatus.unclaimed;
  }
};

export const mapTicketTierResponse = (ticketTier: string): TicketTier => {
  switch (ticketTier) {
    case "STANDARD":
      return TicketTier.standard;
    case "PREMIUM":
      return TicketTier.premium;
    default:
      return TicketTier.vip;
  }
};

export const mapTicketTransactionStatus = (
  transactionStatus: string
): TicketTransactionStatus => {
  switch (transactionStatus) {
    case "REQUESTED":
      return TicketTransactionStatus.requested;
    case "IN_PROGRESS":
      return TicketTransactionStatus.inProgress;
    case "TX_SUBMITTED":
      return TicketTransactionStatus.submitted;
    case "TX_FAILED":
      return TicketTransactionStatus.failed;
    default:
      return TicketTransactionStatus.confirmed;
  }
};

export const generateDateFromTimestamp = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDateString = date.toLocaleDateString("en-GB");
  return formattedDateString;
};

export const generateTimeFromTimestamp = (dateString: string) => {
  const date = new Date(dateString);
  const formattedTimeString = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return formattedTimeString;
};

export const isPastEvent = (eventDatetime: string) => {
  const date = new Date(eventDatetime);
  const currentDate = new Date();
  return currentDate > date;
};
