import { useState } from "react";
import "./email-section.css";

import env from "react-dotenv";
import validator from "email-validator";

type TextInputEvent = React.FormEvent<HTMLInputElement>;

type MessageType = {
  type: "success" | "error";
  message: string;
};

const EmailSection = () => {
  const [email, setEmail] = useState("");
  const [messageData, setMessageData] = useState<MessageType | null>(null);

  const onSubmitEmail = () => {
    const trimmedEmail = email.trim();
    const emailIsValid = validator.validate(trimmedEmail);
    if (!emailIsValid) {
      setMessageData({
        type: "error",
        message: "Please enter a valid email address",
      });
      return;
    }

    const url = env.MAILCHIMP_ACTION_URL;
    // jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (e: any, data: any) => {
    //   if (data?.result === "success") {
    //     setMessageData({
    //       type: "success",
    //       message: data?.msg ?? "Email submitted sucessfully",
    //     });
    //     setEmail("");
    //   } else {
    //     setMessageData({
    //       type: "error",
    //       message: data?.msg ?? "There was an error submitting this email",
    //     });
    //   }
    // });
  };

  const onChange = (event: TextInputEvent) => {
    const value = event.currentTarget.value;
    setEmail(value);
    setMessageData(null);
  };

  return (
    <div className="email-section">
      <div className="container">
        <div className="row flex-row">
          <p
            className="col-md-6 col-12 p1"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            Join our <br />
            mailing list for the latest updates
          </p>

          <div
            className="emailIpt col-md-6 col-12 position-relative"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <input
              type="email"
              name="EMAIL"
              required
              value={email}
              onChange={onChange}
              placeholder="Your Email Address"
            />
            <button className="submit-button" onClick={onSubmitEmail}></button>
          </div>
          {!!messageData && (
            <p
              className={`message ${
                messageData.type === "success"
                  ? "success-message"
                  : "error-message"
              }`}
            >
              {messageData.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export { EmailSection };
