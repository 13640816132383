import { useEffect, useState } from "react";
import "../email-modal/email-modal.css";

import { RemoteManagementUseCase } from "../../use-case/remote-management-use-case";
import { Oval } from "react-loader-spinner";
import { BaseModal } from "../base-modal";
import { useUserData } from "../../hooks/use-user-data";

class ClaimModalConsts {
  static successTimeoutMs = 3000;
}

type Props = {
  activeTicketId: string;
  onCancelClick: () => void;
};

const ClaimModal = ({ onCancelClick, activeTicketId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [claimSuccess, setClaimSuccess] = useState(false);
  const { getUserTicketInfo } = useUserData();

  useEffect(() => {
    if (claimSuccess) {
      setTimeout(() => {
        getUserTicketInfo();
        onCancelClick();
      }, ClaimModalConsts.successTimeoutMs);
    }
  }, [claimSuccess, onCancelClick]);

  const onConfirmButtonClick = async () => {
    setHasError(false);
    if (activeTicketId) setIsLoading(true);
    const response = await RemoteManagementUseCase.claimTicket(activeTicketId);
    setIsLoading(false);
    if (!response.ok) {
      setHasError(true);
      return;
    }

    setClaimSuccess(true);
  };

  const renderFooter = () => {
    if (isLoading) {
      return (
        <div className="loading-container">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#000000"
            strokeWidth={3}
            secondaryColor="#000000"
          />
        </div>
      );
    }
    if (claimSuccess) {
      return (
        <div className="success-container">
          <p>Your ticket has been successfully claimed</p>
        </div>
      );
    }
    return (
      <div className="button-container">
        <button onClick={onConfirmButtonClick}>Yes, confirm</button>
        <button className="cancel-button" onClick={onCancelClick}>
          No, cancel
        </button>
      </div>
    );
  };

  return (
    <BaseModal
      title="Confirm ticket claim."
      onCancelClick={onCancelClick}
      hideClose={isLoading || claimSuccess}
    >
      <p style={{ fontSize: "14px" }}>
        Once you claim your ticket you will not be able to transfer or change
        your ticket. <br /> This action cannot be reverted
      </p>
      {hasError && (
        <p className="email-error">
          There was an error claiming this ticket. Please try again.
          <br /> If the problem persists please contact us.
        </p>
      )}
      <div className="button-container">{renderFooter()}</div>
    </BaseModal>
  );
};

export { ClaimModal };
