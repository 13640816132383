import { ActiveTicket } from "../../../hooks/use-my-ticket-data";
import { useUserData } from "../../../hooks/use-user-data";
import { User } from "../../../models/user";
import { EmptyTicketView } from "../empty-ticket-view";
import { TicketDetailsCell } from "./ticket-details-cell/ticket-details-cell";
import "./ticket-details-view.css";

type Props = {
  user: User;
  onClaimClick: (activeTicket: ActiveTicket) => void;
  onTransferClick: (activeTicket: ActiveTicket) => void;
};

const TicketDetailsView = ({ user, onClaimClick, onTransferClick }: Props) => {
  const hasTickets = user.tickets.length !== 0;
  const { logout } = useUserData();
  return (
    <div className="ticket-details-view">
      <div className="table-container-user">
        <h3>Details</h3>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col" className="hide-mobile">
                Wallet address
              </th>
            </tr>
          </thead>
          <tbody>
            <tr key={"ticket.id"} className="ticket-listing-row">
              <td
                style={{
                  paddingTop: "12px",
                }}
              >
                {user.externalId}
              </td>
              <td style={{ paddingTop: "12px" }} className="hide-mobile">
                {user.walletAddress}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table-mobile">
          <thead>
            <tr>
              <th scope="col">Wallet address</th>
            </tr>
          </thead>
          <tbody>
            <tr key={"ticket.id"} className="ticket-listing-row">
              <td style={{ paddingTop: "12px" }}>{user.walletAddress}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {hasTickets ? (
        <div className="table-container-ticket">
          <h3>Ticket Transfer</h3>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Ticket Id</th>
                <th scope="col">NFT Minting status</th>
                <th scope="col">On Chain token Class ID</th>
                <th scope="col">TX Hash</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {user.tickets.map((ticket, index) => {
                return (
                  <TicketDetailsCell
                    key={`ticket-details-cell-${index}`}
                    ticket={ticket}
                    onClaimClick={onClaimClick}
                    onTransferClick={onTransferClick}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyTicketView />
      )}

      <div className="logout-button-container">
        <button onClick={logout}>Logout</button>
      </div>
    </div>
  );
};

export { TicketDetailsView };
