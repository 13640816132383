import { CHAIN_NAMESPACES, CustomChainConfig } from "@web3auth/base";

const CHAIN_CONFIG = {
  mainnet: {
    displayName: "Ethereum Mainnet",
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x1",
    rpcTarget: `https://rpc.ankr.com/eth`,
    blockExplorer: "https://etherscan.io/",
    ticker: "ETH",
    tickerName: "Ethereum",
  } as CustomChainConfig,
  goerli: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: "https://rpc.ankr.com/eth_goerli",
    blockExplorer: "https://goerli.etherscan.io/",
    chainId: "0x5",
    displayName: "Goerli",
    ticker: "eth",
    tickerName: "Eth",
  } as CustomChainConfig,
  arbitrum_sepolia: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget:
      "https://arbitrum-sepolia.infura.io/v3/4efda295156d477f959dcef8ebc33c5f",
    blockExplorer: "https://sepolia.arbiscan.io/",
    chainId: "0x66eee",
    displayName: "Arbitrum Sepolia",
    ticker: "eth",
    tickerName: "Eth",
  } as CustomChainConfig,
  sepolia: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: "https://rpc.ankr.com/eth_sepolia",
    blockExplorer: "https://sepolia.etherscan.io/",
    chainId: "0xaa36a7",
    displayName: "Sepolia",
    ticker: "eth",
    tickerName: "Eth",
  } as CustomChainConfig,
  mantle_mainnet: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: "https://rpc.mantle.xyz/",
    blockExplorer: "https://explorer.mantle.xyz/",
    chainId: "0x1388",
    displayName: "Mantle",
    ticker: "mnt",
    tickerName: "Mnt",
  } as CustomChainConfig,
  mantle_goerli: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: "https://rpc.testnet.mantle.xyz/",
    blockExplorer: "https://explorer.testnet.mantle.xyz/",
    chainId: "0x1389",
    displayName: "Mantle Testnet",
    ticker: "mnt",
    tickerName: "Mnt",
  } as CustomChainConfig,
};

export { CHAIN_CONFIG };
