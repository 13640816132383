import { ApiError } from "../models/api_error";
import { Result } from "../models/result";
import { ErrorTracking } from "./error-tracking";

class ErrorHandler {
  static handle = <T>(error: any): Result<T, ApiError> => {
    ErrorTracking.handleError(error?.message);
    if (error?.response?.status === 401) {
      return {
        ok: false,
        error: ApiError.unauthenticated,
      };
    }
    return {
      ok: false,
      error: ApiError.unknown,
    };
  };
}

export { ErrorHandler };
