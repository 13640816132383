export enum TicketTier {
  standard = "Standard",
  premium = "Premium",
  vip = "VIP",
}

export enum TicketStatus {
  unclaimed = "Unclaimed",
  claimed = "Claimed",
  pendingClaimed = "Pending Claimed",
}

export enum TicketTransactionStatus {
  requested = "Requested",
  inProgress = "In Progress",
  submitted = "Submitted",
  failed = "Failed",
  confirmed = "Confirmed",
}

export type Ticket = {
  id: string;
  eventLocation: "Lightcycle";
  eventTitle: "Robbie Williams";
  status: TicketStatus;
  tier: TicketTier;
  orderId: string;
  eventInformation: {
    isPastEvent: boolean;
    eventDate: string;
    eventTime: string;
    eventId: string;
    eventbriteId: string;
  };
  mintingInformation: {
    transactionStatus: TicketTransactionStatus;
    tokenId: number;
    transactionHash?: string | undefined;
  };
};
