import { Endpoint } from "../consts/endpoint";
import api from "../network/api";

class RemoteDataSource {
  static loginUser = async (): Promise<any> => {
    const response = await api.post(Endpoint.login);
    return response;
  };

  static getUserTicketinfo = async (): Promise<any> => {
    const response = await api.get(Endpoint.getUserTickets);
    return response;
  };

  static createOrder = async (orderId: string): Promise<any> => {
    const response = await api.post(Endpoint.createOrder, {
      eventbrite_order_id: orderId,
    });
    return response;
  };

  static transferTicket = async (
    ticketId: string,
    emailTo: string
  ): Promise<any> => {
    const response = await api.post(Endpoint.transferTicket, {
      email_to: emailTo,
      ticket_uid: ticketId,
    });
    return response;
  };

  static claimTicket = async (ticketId: string): Promise<any> => {
    const response = await api.post(Endpoint.claimTicket, {
      ticket_uid: ticketId,
    });
    return response;
  };
}

export { RemoteDataSource };
