import { ReactNode, useEffect } from "react";
import { useUserData } from "../../hooks/use-user-data";
import "./error-modal.css";

type Props = {
  children: ReactNode;
};

class ErrorWrapperConsts {
  static errorMessageTimeoutMs = 5000;
}

const ErrorWrapper = ({ children }: Props) => {
  const { error, clearError } = useUserData();

  useEffect(() => {
    // when the errorMessage is not null, the alert is displayed for 3 seconds
    if (!error?.title) return;

    setTimeout(() => {
      clearError();
    }, ErrorWrapperConsts.errorMessageTimeoutMs);
  }, [error, clearError]);

  return (
    <div className="app-container">
      {children}

      <div
        className={`alert alert-danger fade ${
          !!error?.title ? "show pe-auto" : "hide pe-none"
        } error-modal`}
        role="alert"
      >
        <strong>{error?.title}:</strong>
        <p>{error?.message}</p>
        <button
          type="button"
          className="close button"
          data-dismiss="alert"
          aria-label="Close"
          onClick={clearError}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
};

export { ErrorWrapper };
