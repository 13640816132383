import { useUserData } from "../../../hooks/use-user-data";
import "./ticket-error-view.css";

type Props = {
  onRetryClick: () => void;
};

const TicketErrorView = ({ onRetryClick }: Props) => {
  const { logout } = useUserData();

  return (
    <div className="ticket-error-view">
      <h2>Something went wrong</h2>
      <p>There was an error fetching your details. Please try again</p>
      <button onClick={onRetryClick}>Retry</button>

      <div className="logout-button-container">
        <button onClick={logout}>Logout</button>
      </div>
    </div>
  );
};

export { TicketErrorView };
