import "./explore-section.css";
import { useEffect, useRef, useState } from "react";

import SlideShow1 from "../../../assets/images/slide-show-1.jpg";
import SlideShow2 from "../../../assets/images/slide-show-2.jpg";
import SlideShow3 from "../../../assets/images/slide-show-3.jpg";
import SlideShow4 from "../../../assets/images/slide-show-4.jpg";
import SlideShow5 from "../../../assets/images/slide-show-5.jpg";
import SlideShow6 from "../../../assets/images/slide-show-6.jpg";
import SlideShow7 from "../../../assets/images/slide-show-7.jpg";
import SlideShow8 from "../../../assets/images/slide-show-8.jpg";
import SlideShow9 from "../../../assets/images/slide-show-9.jpg";
import SlideShow10 from "../../../assets/images/slide-show-10.jpg";
import SlideShow11 from "../../../assets/images/slide-show-11.jpg";
import SlideShow12 from "../../../assets/images/slide-show-12.jpg";
import SlideShow13 from "../../../assets/images/slide-show-13.jpg";
import SlideShow14 from "../../../assets/images/slide-show-14.jpg";
import SlideShow15 from "../../../assets/images/slide-show-15.jpg";
import SlideShow16 from "../../../assets/images/slide-show-16.jpg";
import SlideShow17 from "../../../assets/images/slide-show-17.jpg";
import SlideShow18 from "../../../assets/images/slide-show-18.jpg";
import SlideShow19 from "../../../assets/images/slide-show-19.jpg";
import SlideShow20 from "../../../assets/images/slide-show-20.jpg";
import SlideShow21 from "../../../assets/images/slide-show-21.jpg";
const SECTION_TOP_PADDING_PX = 220;

const ExploreSection = () => {
  const containerRef = useRef<HTMLParagraphElement | null>(null);

  const textRef = useRef<HTMLParagraphElement | null>(null);
  const imageContainerRef = useRef<HTMLParagraphElement | null>(null);

  const [animateTextIn, setAnimateTextIn] = useState(false);
  const [animateTextOut, setAnimateTextOut] = useState(false);
  const [scaleDown, setScaleDown] = useState(false);

  const handleScroll = () => {
    if (textRef.current) {
      const { innerHeight } = window;

      const containerHeight =
        containerRef.current?.getBoundingClientRect().height;

      const imageContainerTop =
        imageContainerRef.current?.getBoundingClientRect().top;

      setScaleDown((imageContainerTop ?? 0) < innerHeight / 1.8);

      const containerMinY = containerRef.current?.getBoundingClientRect().y;

      const adjustedContainerYOffset =
        (containerMinY ?? 0) - SECTION_TOP_PADDING_PX;

      const textInTrigger = adjustedContainerYOffset < innerHeight / 8;
      setAnimateTextIn(textInTrigger);

      const textOutPoint = (containerHeight ?? 0) - innerHeight / 1.2;
      const textOutTrigger = -adjustedContainerYOffset > textOutPoint;
      setAnimateTextOut(textOutTrigger);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={containerRef} className="explore-section">
      <div className="container relative">
        <div
          ref={textRef}
          className="text-container"
          style={{
            willChange: "transform",
            transformStyle: "preserve-3d",
            position: "fixed",
            top: animateTextIn ? (animateTextOut ? "-200px" : "45%") : "120%",
            transition: "all 0.5s ease",
            transform: `translateX(-50%) ${
              scaleDown ? "scale(0.8)" : "scale(1)"
            }`,
          }}
        >
          <p className="text-large180">
            A TRIP DOWN <br />
            MEMORY LANE
          </p>
        </div>
        <div ref={imageContainerRef} className={`image-container`}>
          <img className="menImg image1" src={SlideShow1} alt="" />
          <img className="menImg image2" src={SlideShow2} alt="" />
          <img className="menImg image4" src={SlideShow4} alt="" />
          <img className="menImg image5" src={SlideShow5} alt="" />
          <img className="menImg image6" src={SlideShow6} alt="" />
          <img className="menImg image7" src={SlideShow7} alt="" />
          <img className="menImg image8" src={SlideShow8} alt="" />
          <img className="menImg image9" src={SlideShow9} alt="" />
          <img className="menImg image10" src={SlideShow10} alt="" />
          <img className="menImg image11" src={SlideShow11} alt="" />
          <img className="menImg image12" src={SlideShow12} alt="" />
          <img className="menImg image13" src={SlideShow13} alt="" />
          <img className="menImg image14" src={SlideShow14} alt="" />
          <img className="menImg image15" src={SlideShow15} alt="" />
          <img className="menImg image16" src={SlideShow16} alt="" />
          <img className="menImg image17" src={SlideShow17} alt="" />
          <img className="menImg image18" src={SlideShow18} alt="" />
          <img className="menImg image19" src={SlideShow19} alt="" />
          <img className="menImg image20" src={SlideShow20} alt="" />
          <img className="menImg image21" src={SlideShow21} alt="" />
        </div>
      </div>
    </div>
  );
};

export { ExploreSection };
