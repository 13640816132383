import "./info_card.css";

type Props = {
  image: string;
  title: string;
  description: string;
  animationDelay: string;
};

const InfoCard = ({ title, description, image, animationDelay }: Props) => {
  return (
    <div
      className="item col-12 col-md-4"
      data-aos="fade-up"
      data-aos-duration={animationDelay}
    >
      <img src={image} alt="" />
      <p className="p1">{title}</p>
      <p className="p2 col-9">{description}</p>
    </div>
  );
};

export { InfoCard };
