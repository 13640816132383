import {
  LocalStoreKey,
  deletLocalStoreItem,
  getLocalStoreItem,
  setLocalStoreItem,
} from "../storage/local-storage";

class LoginFlagService {
  private static flagValue = "true";
  static getRouteToTicketsAfterLoginFlag() {
    const flag = getLocalStoreItem(LocalStoreKey.routeToTicketsAfterLoginFlag);
    return flag === LoginFlagService.flagValue;
  }

  static setRouteToTicketsAfterLoginFlag() {
    setLocalStoreItem(
      LoginFlagService.flagValue,
      LocalStoreKey.routeToTicketsAfterLoginFlag
    );
  }

  static deleteRouteToTicketsAfterLoginFlag = () => {
    deletLocalStoreItem(LocalStoreKey.routeToTicketsAfterLoginFlag);
  };
}
export { LoginFlagService };
