import { Header } from "../../components/header";
import { MyTicketsView } from "../../components/my-ticket-components/my-tickets-view";
import { TicketDetailsView } from "../../components/my-ticket-components/ticket-details-view";
import {
  ActiveTicketType,
  Tabs,
  useMyTicketData,
} from "../../hooks/use-my-ticket-data";
import { Oval } from "react-loader-spinner";
import "./my-tickets.css";
import { TicketErrorView } from "../../components/my-ticket-components/ticket-error-view";
import { EmailModal } from "../../components/email-modal";
import { ClaimModal } from "../../components/claim-modal";

const MyTicketsPage = () => {
  const {
    currentTab,
    user,
    isLoading,
    hasError,
    activeTicketId,
    upcomingTickets,
    pastTickets,
    onTabChange,
    getUserTicketInfo,
    onClaimClick,
    onTransferClick,
    hideModal,
  } = useMyTicketData();

  return (
    <div className="myTicket">
      {activeTicketId?.type === ActiveTicketType.transfer && (
        <EmailModal
          onCancelClick={hideModal}
          activeTicketId={activeTicketId.ticketId}
        />
      )}

      {activeTicketId?.type === ActiveTicketType.claim && (
        <ClaimModal
          onCancelClick={hideModal}
          activeTicketId={activeTicketId.ticketId}
        />
      )}
      <Header notHome />
      <section>
        <div className="ticket_container ticketBan d-flex align-items-center text-uppercase">
          {currentTab}
        </div>
      </section>
      <section className="ticketCon">
        <div className="tabs">
          <div className="ticket_container d-flex flex-row">
            <div
              className={`tab ${currentTab === Tabs.myTickets && "active"}`}
              onClick={() => onTabChange(Tabs.myTickets)}
            >
              My tickets
            </div>
            <div
              className={`tab ${currentTab === Tabs.ticketDetails && "active"}`}
              onClick={() => onTabChange(Tabs.ticketDetails)}
            >
              Details & ticket transfer
            </div>
          </div>
        </div>
        <div className="content">
          {!!isLoading && (
            <div className="loading-container">
              <Oval
                visible={true}
                height="40"
                width="40"
                color="#000000"
                strokeWidth={3}
                secondaryColor="#000000"
              />
            </div>
          )}
          {!isLoading && !hasError && !!user && (
            <div className="ticket_container">
              {currentTab === Tabs.myTickets && (
                <MyTicketsView
                  upcomingTickets={upcomingTickets}
                  pastTickets={pastTickets}
                />
              )}
              {currentTab === Tabs.ticketDetails && !!user && (
                <TicketDetailsView
                  user={user}
                  onClaimClick={onClaimClick}
                  onTransferClick={onTransferClick}
                />
              )}
            </div>
          )}
          {!isLoading && !!hasError && (
            <TicketErrorView onRetryClick={getUserTicketInfo} />
          )}
        </div>
      </section>
    </div>
  );
};

export { MyTicketsPage };
