import { PartnerCard } from "./partner_card";
import "./partner_section.css";

import VertexLabsSmall from "../../../assets/images/par1.png";
import VertexLabsLarge from "../../../assets/images/par1_h5.png";

import CaduceusSmall from "../../../assets/images/par2.png";
import CaduceusLarge from "../../../assets/images/par2_h5.png";

import HapeSmall from "../../../assets/images/par3.png";
import HapeLarge from "../../../assets/images/par3_h5.png";

import UnrealSmall from "../../../assets/images/par4.png";
import UnrealLarge from "../../../assets/images/par4_h5.png";

import EpicGamesSmall from "../../../assets/images/par5.png";
import EpicGamesLarge from "../../../assets/images/par5_h5.png";

import RWFanClubSmall from "../../../assets/images/par6.png";
import RWFanClubLarge from "../../../assets/images/par6_h5.png";

import RWFanFestSmall from "../../../assets/images/par7.png";
import RWFanFestLarge from "../../../assets/images/par7_h5.png";

import RobbieWSmall from "../../../assets/images/par8.png";
import RobbieWLarge from "../../../assets/images/par8_h5.png";

import DecryptSmall from "../../../assets/images/par9.png";
import DecryptLarge from "../../../assets/images/par9_h5.png";

import BYBT from "../../../assets/images/bybt.png";

const PartnerSection = () => {
  return (
    <div className="partner-section">
      <div className="container">
        <p className="p1" data-aos="fade-up" data-aos-duration="1000">
          our partners
        </p>
        <p
          className="p2 col-md-9 col-12"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          We are proud to collaborate with our partners and sponsors who play a
          pivotal role in bringing this event to life. Their support is
          essential in creating a memorable experience for all attendees.
        </p>

        <div className="partners d-flex flex-wrap flex-row justify-content-between">
          <PartnerCard
            smallImage={VertexLabsSmall}
            largeImage={VertexLabsLarge}
          />
          <PartnerCard smallImage={CaduceusSmall} largeImage={CaduceusLarge} />
          <PartnerCard smallImage={HapeSmall} largeImage={HapeLarge} />
          <PartnerCard smallImage={UnrealSmall} largeImage={UnrealLarge} />
          <PartnerCard
            smallImage={EpicGamesSmall}
            largeImage={EpicGamesLarge}
          />
          <PartnerCard
            smallImage={RWFanClubSmall}
            largeImage={RWFanClubLarge}
          />
          <PartnerCard
            smallImage={RWFanFestSmall}
            largeImage={RWFanFestLarge}
          />
          <PartnerCard smallImage={RobbieWSmall} largeImage={RobbieWLarge} />
          <PartnerCard smallImage={DecryptSmall} largeImage={DecryptLarge} />
          <PartnerCard smallImage={BYBT} largeImage={BYBT} />
        </div>
      </div>
    </div>
  );
};

export { PartnerSection };
