import "./digital_enhancement_section.css";
import ExpetImage1 from "../../../assets/images/expect1.png";
import ExpetImage2 from "../../../assets/images/expect2.png";
import ExpetImage3 from "../../../assets/images/expect3.png";
import { InfoCard } from "./info_card";
import { LegacyRef, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const DigitalEnhancementSection = () => {
  const [lettersRef, setLettersRef] = useArrayRef();
  const triggerRef = useRef(null);

  function useArrayRef() {
    const lettersRef = useRef<any>([]);
    lettersRef.current = [];
    return [lettersRef, (ref: any) => ref && lettersRef.current?.push(ref)];
  }
  gsap.registerPlugin(ScrollTrigger);

  const titleText = "A VIRTUAL MUSIC EXPERIENCE CELEBRATING HIS SOLO CAREER";

  useEffect(() => {
    const anim = gsap.to((lettersRef as any).current, {
      scrollTrigger: {
        trigger: triggerRef.current,
        scrub: true,
        start: "top center",
        end: "bottom 70%",
      },
      opacity: 1,
      duration: 1,
      stagger: 1,
    });
    return () => {
      anim.kill();
    };
  }, []);

  return (
    <div className="digital-enhancement">
      <div
        className="reveal"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-anchor-placement="top-center"
      >
        <div ref={triggerRef}>
          {titleText.split("").map((letter, index) => (
            <span className="reveal-text" key={index} ref={setLettersRef}>
              {letter}
            </span>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="secondText mb-5">
          <p className="p2" data-aos="fade-up" data-aos-duration="800">
            Get ready for an amazing experience that will bring you closer to
            Robbie Williams than ever before.
          </p>
          <div
            className="row text mb-4"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="col-12 col-md-6">
              <p className="p3">
                In a global first, he will be taking over the virtual world for
                an unprecedented music event.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <p className="p4">
                It’s a chance to connect with other fans around the world,
                celebrate a legend, and win unprecedented prizes.
              </p>
            </div>
          </div>
        </div>
        <div className="toExpect" data-aos="fade-up" data-aos-duration="800">
          <p>WHAT TO EXPECT</p>
        </div>
      </div>

      <div className="row expect d-flex justify-content-around align-items-top">
        <InfoCard
          title="ROBBIE WILLIAMS REIMAGINED"
          description={`Marvel as he is brought to life as a 3D Avatar in our stunning virtual environment.\n\nEnjoy this from the comfort of your own home using your computer.`}
          image={ExpetImage1}
          animationDelay="500"
        />
        <InfoCard
          title="CELEBRATE XXV: WIN PRIZES"
          description={`Rate yourself as his number one fan? Prove it by competing for Fan Points in quizzes and challenges against other fans. \n\nPoints mean prizes. Earn the chance to win one of a kind rewards.`}
          image={ExpetImage2}
          animationDelay="1000"
        />
        <InfoCard
          title="PREMIER SCREENING"
          description={`Your ticket unlocks access to an exclusive screening of his legendary performance at the Elbphilharmonie in Hamburg. \n\nSee "Angels” reimagined with AI, and performed for the first time live with an orchestra.`}
          image={ExpetImage3}
          animationDelay="1500"
        />
      </div>
    </div>
  );
};
export { DigitalEnhancementSection };
