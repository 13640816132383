import env from "react-dotenv";

export const isProduction = env.ENVIRONMENT === "production";
export const baseUrl = env.BASE_URL || "";

export const twitterUrl = "https://twitter.com/LightCycle_City";
export const discordUrl = "https://discord.com/invite/pdtsD2wMx5";
export const instagramUrl = "https://www.instagram.com/lightcycle_city/";
export const facebookUrl = "https://www.facebook.com/LightCycleMetaverse/";

export const lightcycleUrl = "https://lightcycle.city/";

export const EVENT_MODAL_ID = "event_modal_id_lightcycle";
export const cookieName = "rw_lightcycle_cooke";
