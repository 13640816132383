import { SummarisedTickets } from "../../../hooks/use-my-ticket-data";
import { TicketListing } from "../ticket-listing";

import "./my-ticket-section.css";

type Props = {
  title: String;
  tickets: SummarisedTickets[];
};
const MyTicketSection = ({ title, tickets }: Props) => {
  if (tickets.length === 0) {
    return (
      <div className="my-ticket-container">
        <h3>{title}</h3>
        <p>You have no upcoming tickets</p>
      </div>
    );
  }
  return (
    <div className="my-ticket-container">
      <h3>{title}</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Event date</th>
              <th scope="col">Event time</th>
              <th scope="col">Event title</th>
              <th scope="col">Location</th>
              <th scope="col">Tickets</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket, index) => {
              return (
                <TicketListing
                  key={ticket.id}
                  ticket={ticket}
                  isFirstChild={index === 0}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { MyTicketSection };
