import { Web3Auth, Web3AuthOptions } from "@web3auth/modal";
import env from "react-dotenv";
import { CHAIN_CONFIG } from "../consts/chain-config";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import {
  ADAPTER_EVENTS,
  CONNECTED_EVENT_DATA,
  WALLET_ADAPTERS,
} from "@web3auth/base";
import { useEffect, useState } from "react";
import { TokenService } from "../data-source/token-service";
import { isProduction } from "../consts/consts";
import { openLoginMethods } from "../consts/web3-auth-consts";
import { LoginFlagService } from "../data-source/login-flag-service";

export enum AuthEvent {
  connected,
  disconnected,
  connecting,
  error,
}

type Props = {
  onAuthEvent: (event: AuthEvent) => Promise<void>;
};
const useWeb3Auth = ({ onAuthEvent }: Props) => {
  const [isConnected, setIsConnected] = useState(false);

  const [web3AuthInstance, setWeb3AuthInstance] = useState<
    Web3Auth | undefined
  >();
  const [hasError, setHasError] = useState(false);

  const handleSubscribedEvents = (web3Auth: Web3Auth) => {
    web3Auth.on(ADAPTER_EVENTS.CONNECTED, (data: CONNECTED_EVENT_DATA) => {
      console.log("WEB3AUTH - CONNECTED", data);
      setIsConnected(true);
      onAuthEvent(AuthEvent.connected);
    });

    web3Auth.on(ADAPTER_EVENTS.CONNECTING, () => {
      console.log("WEB3AUTH - CONNECTING");
      onAuthEvent(AuthEvent.connecting);
    });

    web3Auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
      console.log("WEB3AUTH - DISCONNECTED");
      setIsConnected(false);
      LoginFlagService.deleteRouteToTicketsAfterLoginFlag();
      onAuthEvent(AuthEvent.disconnected);
    });

    web3Auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
      console.warn("WEB3AUTH - ERROR", error);
      LoginFlagService.deleteRouteToTicketsAfterLoginFlag();
      setIsConnected(false);
      onAuthEvent(AuthEvent.error);
    });
  };

  const setup = async () => {
    const web3AuthOptions: Web3AuthOptions = {
      clientId: env.WEB3AUTH_CLIENT_ID || "",
      chainConfig: isProduction
        ? CHAIN_CONFIG.mantle_mainnet
        : CHAIN_CONFIG.mantle_goerli,
      authMode: "WALLET",
      web3AuthNetwork: isProduction ? "sapphire_mainnet" : "sapphire_devnet",
    };
    const web3Auth = new Web3Auth(web3AuthOptions);

    const openLoginAdapter = new OpenloginAdapter({
      adapterSettings: {
        clientId: env.WEB3AUTH_CLIENT_ID || "",
        uxMode: "redirect",
      },
    });

    web3Auth.configureAdapter(openLoginAdapter);
    setWeb3AuthInstance(web3Auth);
    console.log("SET INSTANCE");
    await web3Auth.initModal({
      modalConfig: {
        [WALLET_ADAPTERS.OPENLOGIN]: {
          label: "openlogin",
          loginMethods: openLoginMethods,
        },
        // Disable Wallet Connect V2
        [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
          label: "wallet_connect",
          showOnModal: false,
        },
        // Disable Metamask
        [WALLET_ADAPTERS.METAMASK]: {
          label: "metamask",
          showOnModal: false,
        },
        [WALLET_ADAPTERS.COINBASE]: {
          label: "coinbase",
          showOnModal: false,
        },
      },
    });
  };

  useEffect(() => {
    if (web3AuthInstance) {
      handleSubscribedEvents(web3AuthInstance);
    }
  }, [web3AuthInstance]);

  useEffect(() => {
    if (!web3AuthInstance) {
      setup();
    }
  }, []);

  const connect = async () => {
    if (!web3AuthInstance) {
      setHasError(true);
      return;
    }
    try {
      await web3AuthInstance.connect();
    } catch (error) {
      LoginFlagService.deleteRouteToTicketsAfterLoginFlag();
      console.warn(`Connect wallet error: ${error}`);
    }
  };

  const disconnect = async () => {
    if (!web3AuthInstance) {
      setHasError(true);
      return;
    }
    if (!web3AuthInstance.connected) {
      console.log("web3auth not connected");
      return;
    }
    console.log("Disconnecting");
    await web3AuthInstance.logout();
  };

  const getUserInfo = async () => {
    if (!web3AuthInstance) {
      console.warn("No web3AuthInstance - getUserInfo");
      setHasError(true);
      return null;
    }
    const user = await web3AuthInstance.getUserInfo();
    if (user.idToken) {
      console.log({ user });
      TokenService.updateLocalAccessToken(user.idToken);
    }
    return user;
  };

  return {
    connect,
    disconnect,
    getUserInfo,
    hasError,
    isConnected,
  };
};

export { useWeb3Auth };
