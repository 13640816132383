export const openLoginMethods = {
  facebook: {
    name: "facebook",
    showOnModal: false,
  },
  google: {
    name: "google",
    showOnModal: false,
  },
  reddit: {
    name: "reddit",
    showOnModal: false,
  },
  twitter: {
    name: "twitter",
    showOnModal: false,
  },
  discord: {
    name: "discord",
    showOnModal: false,
  },
  twitch: {
    name: "twitch",
    showOnModal: false,
  },
  apple: {
    name: "apple",
    showOnModal: false,
  },
  line: {
    name: "line",
    showOnModal: false,
  },
  github: {
    name: "github",
    showOnModal: false,
  },
  kakao: {
    name: "kakao",
    showOnModal: false,
  },
  linkedin: {
    name: "linkedin",
    showOnModal: false,
  },
  weibo: {
    name: "weibo",
    showOnModal: false,
  },
  wechat: {
    name: "wechat",
    showOnModal: false,
  },
  sms_passwordless: {
    name: "sms_passwordless",
    showOnModal: false,
  },
};
