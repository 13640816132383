import RobbieWilliamsImage from "../../../assets/images/ROBBIE WILLIAMS.png";
import HumanImage2 from "../../../assets/images/rw_head.png";
import SixthBtmBg from "../../../assets/images/sixthBtmBg.png";

import "./robbie-williams-section.css";

const RobbieWilliamsSection = () => {
  return (
    <div className="robbie-williams">
      <p className="p1">“</p>
      <img
        className="robbie-williams-image"
        src={RobbieWilliamsImage}
        data-aos="fade-up"
        data-aos-duration="1000"
        alt=""
      />

      <div className="sixthImg" data-aos="fade-up" data-aos-duration="1500">
        <img className="h-100 w-100" src={HumanImage2} alt="" />
      </div>

      <div className="row rightBtText">
        <div className="col-md-6 col-4"></div>
        <div className="col-md-6 col-8 sixText d-flex flex-column align-items-start">
          <p data-aos="fade-left" data-aos-duration="800">
            Staying connected with my fans has always been important to me. I’m
            fascinated by the Web3 space - it’s full of creativity and
            mind-blowing potential. It’s also a big new market for music
            discovery. As an artist, I’m always looking for ways to push forward
            so this is the perfect next step in that journey.
          </p>
          <p
            className="sixText-p2"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            The talented development team at LightCycle have produced some
            incredible work and I’m very excited to see it all brought to life
            in full 3D glory.
          </p>
          <p
            className="sixText-p3"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            21 Sep, 2023
          </p>
          <p
            className="sixText-p4"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            Variety Magazine
          </p>
        </div>
      </div>

      <div className="sixthBtmBg">
        <img src={SixthBtmBg} alt="" />
      </div>
    </div>
  );
};
export { RobbieWilliamsSection };
