import axios from "axios";

import { baseUrl } from "../consts/consts";
import { TokenService } from "../data-source/token-service";
import { ErrorTracking } from "../error-handling/error-tracking";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    ErrorTracking.handleInfoBreadcrumb(
      `sending request to url - ${config.url}`
    );

    if (typeof window !== "undefined") {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((config) => {
  ErrorTracking.handleInfoBreadcrumb(
    `received request from url - ${config?.config?.url}`
  );
  return config;
});

export default instance;
