import { useEffect, useLayoutEffect, useRef } from "react";
import "./home.css";
import { HeroSection } from "../../components/home-sections/hero_section";
import AOS from "aos";
import "aos/dist/aos.css";
import { Header } from "../../components/header";
import { DigitalEnhancementSection } from "../../components/home-sections/digital_enhancement_section";
import { DigitalSpectacleSection } from "../../components/home-sections/digital_spectacle_section/digital_spectacle_section";
import { TicketSection } from "../../components/home-sections/ticket_section";
import { ExploreSection } from "../../components/home-sections/explore-section";
import { RobbieWilliamsSection } from "../../components/home-sections/robbie-williams-section";
import { PartnerSection } from "../../components/home-sections/partner_section";
import { CommunitySection } from "../../components/home-sections/community-section";
import { FaqSection } from "../../components/home-sections/faq-section";
import { EmailSection } from "../../components/home-sections/email-section";
import { FooterSection } from "../../components/home-sections/footer-section";
import { useUserData } from "../../hooks/use-user-data";
import { Oval } from "react-loader-spinner";

const HomePage = () => {
  const { isConnecting } = useUserData();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);

  const fifthSectionRef = useRef<HTMLParagraphElement | null>(null);
  return (
    <div className="app">
      {isConnecting && (
        <div className="loading-container">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#FFFFFF"
            strokeWidth={3}
            secondaryColor="#cccccc"
          />
        </div>
      )}
      <Header />
      <section className="hero-section position-relative">
        <HeroSection />
      </section>
      <section className="second-section">
        <DigitalEnhancementSection />
      </section>
      <section className="third-section">
        <DigitalSpectacleSection />
      </section>
      <section className="forth-section">
        <TicketSection />
      </section>
      <section className="fifth-section" ref={fifthSectionRef}>
        <ExploreSection />
      </section>
      <section className="sixth-section">
        <RobbieWilliamsSection />
      </section>
      <section className="seventh-section">
        <PartnerSection />
      </section>
      <section className="eighth-section">
        <CommunitySection />
      </section>
      <section className="ninth-section">
        <FaqSection />
      </section>
      <section className="joinOur-section">
        <EmailSection />
      </section>
      <footer>
        <FooterSection />
      </footer>
    </div>
  );
};

export { HomePage };
