import { RouteNames } from "../../consts/route-names";
import { useNavigate } from "react-router-dom";
import "./header.css";
import { useUserData } from "../../hooks/use-user-data";

import LogoBL from "../../assets/images/logo_bl.png";
import Logo from "../../assets/images/logo.png";
import RwB1 from "../../assets/images/rw_bl.png";
import Rw from "../../assets/images/rw.png";
import UserBl from "../../assets/images/user_bl.png";
import User from "../../assets/images/user.png";
import { EVENT_MODAL_ID } from "../../consts/consts";

type Props = {
  notHome?: boolean;
};

const Header = ({ notHome }: Props) => {
  const navigate = useNavigate();
  const { login, isConnected } = useUserData();

  const onUserIconClick = async () => {
    if (isConnected) {
      navigate(RouteNames.myTickets);
    } else {
      await login({ routeToTicketsOnSuccess: true });
    }
  };

  const onLogoClick = () => {
    navigate(RouteNames.home);
  };

  return (
    <header className={`header ${notHome && "ticketContent"}`}>
      <div className={`content `}>
        <div className="logo" onClick={onLogoClick}>
          {notHome ? <img src={LogoBL} alt="" /> : <img src={Logo} alt="" />}
        </div>
        <div className="logo-rw">
          {notHome ? <img src={RwB1} alt="" /> : <img src={Rw} alt="" />}
        </div>
        <div className="right-side d-flex">
          <div className="user-icon" onClick={onUserIconClick}>
            {notHome ? <img src={UserBl} alt="" /> : <img src={User} alt="" />}
          </div>
          <button
            className={`button ${notHome && "inverse-button"}`}
            id={EVENT_MODAL_ID}
            style={isConnected ? { display: "block" } : { display: "none" }}
          >
            BOOK TICKETS
          </button>
          <button
            className={`button ${notHome && "inverse-button"}`}
            onClick={() => login({ routeToTicketsOnSuccess: false })}
            style={!isConnected ? { display: "block" } : { display: "none" }}
          >
            BOOK TICKETS
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
