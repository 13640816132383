export enum LocalStoreKey {
  accessToken = "lightcycle-access-token",
  routeToTicketsAfterLoginFlag = "route-to-tickets-after-login-flag",
}

export const getLocalStoreItem = (key: LocalStoreKey): string | null => {
  return localStorage.getItem(key);
};

export const setLocalStoreItem = (value: string, key: LocalStoreKey) => {
  localStorage.setItem(key, value);
};

export const deletLocalStoreItem = (key: LocalStoreKey) => {
  localStorage.removeItem(key);
};
