import { ReactNode, createContext, useRef, useState } from "react";
import { User } from "../models/user";
import { AuthEvent, useWeb3Auth } from "../hooks/use-web3-auth";
import { RemoteManagementUseCase } from "../use-case/remote-management-use-case";
import { ApiError } from "../models/api_error";
import { TokenService } from "../data-source/token-service";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteNames } from "../consts/route-names";
import { LoginFlagService } from "../data-source/login-flag-service";

type LoginProps = {
  routeToTicketsOnSuccess: boolean;
};

type EventbriteEvent = {
  orderId: string;
};

interface UserContextInterface {
  user: User | undefined;
  isLoading: boolean;
  error: ErrorMessage | undefined;
  isConnected: boolean;
  isConnecting: boolean;
  login: ({ routeToTicketsOnSuccess }: LoginProps) => Promise<void>;
  logout: () => Promise<void>;
  clearError: () => void;
  getUserTicketInfo: () => Promise<void>;
  createOrder: (
    orderId: string,
    { isRetry }: { isRetry: boolean }
  ) => Promise<{
    success: boolean;
  }>;
  handleOrderCompleted: (event: EventbriteEvent) => Promise<void>;
  setGlabalError: (errorData: ErrorMessage) => void;
}

export const UserContext = createContext<UserContextInterface>({
  user: undefined,
  isLoading: false,
  error: undefined,
  isConnected: false,
  isConnecting: false,
  login: async () => {},
  logout: async () => {},
  clearError: async () => {},
  getUserTicketInfo: async () => {},
  createOrder: async () => ({ success: false }),
  handleOrderCompleted: async (event: EventbriteEvent) => {},
  setGlabalError: () => {},
});

export enum UserError {
  login,
  getUserInfo,
  createOrder,
}

type ErrorMessage = {
  title?: string;
  message?: string;
  errorType: UserError;
};
type Props = {
  children?: ReactNode;
};

const UserProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorMessage | undefined>();
  const navigate = useNavigate();

  const setGlabalError = (errorData: ErrorMessage) => {
    setError(errorData);
  };

  const onAuthEvent = async (authEvent: AuthEvent) => {
    switch (authEvent) {
      case AuthEvent.connecting:
        setIsLoading(true);
        break;
      case AuthEvent.connected:
        await onWeb3AuthConnect();
        break;
      case AuthEvent.disconnected:
        break;
      case AuthEvent.error:
        break;
    }
  };
  const {
    connect,
    getUserInfo,
    hasError: hasWeb3AuthError,
    disconnect,
    isConnected,
  } = useWeb3Auth({ onAuthEvent });

  const login = async ({ routeToTicketsOnSuccess = false }: LoginProps) => {
    TokenService.deleteLocalAccessToken();
    if (routeToTicketsOnSuccess) {
      LoginFlagService.setRouteToTicketsAfterLoginFlag();
    }

    await connect();
  };

  const onWeb3AuthConnect = async () => {
    const web3User = await getUserInfo();
    if (!web3User) {
      setIsLoading(false);
      logout();
      return;
    }
    const loginResult = await RemoteManagementUseCase.loginUser();

    if (!loginResult.ok) {
      setError({
        title: "Login Error",
        message:
          "There was an unexpected error when loggin in. Please try again",
        errorType: UserError.login,
      });
      setIsLoading(false);
      await logout();
      return;
    } else {
      await getUserTicketInfo();
    }
  };

  const getUserTicketInfo = async () => {
    setIsLoading(true);
    const userResponse = await RemoteManagementUseCase.getUser();

    if (!userResponse.ok) {
      if (userResponse.error === ApiError.unauthenticated) {
        setError({
          title: "Login Error",
          message:
            "There was an unexpected error when loggin in. Please try again",
          errorType: UserError.login,
        });
        await logout();
      }
      setError({
        errorType: UserError.getUserInfo,
      });
      setIsLoading(false);
      return;
    }
    setUser(userResponse.value);
    setIsLoading(false);
    const routeToTicketsAfterLoginFlag =
      LoginFlagService.getRouteToTicketsAfterLoginFlag();
    if (routeToTicketsAfterLoginFlag) {
      LoginFlagService.deleteRouteToTicketsAfterLoginFlag();
      navigate(RouteNames.myTickets);
    }
  };

  const logout = async () => {
    await disconnect();
    TokenService.deleteLocalAccessToken();
    navigate(RouteNames.home);
  };

  const clearError = () => {
    setError(undefined);
  };

  const createOrder = async (orderId: string, { isRetry = false }) => {
    const response = await RemoteManagementUseCase.createOrder(orderId);
    console.log("Order response: ", response);
    if (!response.ok) {
      if (isRetry) {
        setError({
          title: "Order Error",
          message:
            "There was an unexpected error creating your order. Please contact us to resolve this issue",
          errorType: UserError.createOrder,
        });
      } else {
        await createOrder(orderId, { isRetry: true });
      }
      return { success: false };
    }
    return { success: true };
  };

  const handleOrderCompleted = async (event: EventbriteEvent) => {
    console.log("Handle Order Completed ", event);
    const createOrderResponse = await createOrder(event.orderId, {
      isRetry: false,
    });
    if (createOrderResponse.success) {
      const pathname = window.location.pathname;
      console.log("pathname: ", pathname);
      console.log("RouteName: ", RouteNames.myTickets);
      console.log(pathname === RouteNames.myTickets);

      if (pathname === RouteNames.myTickets) {
        getUserTicketInfo();
      } else {
        navigate(RouteNames.myTickets);
      }
    }
  };

  const providerValues = {
    user,
    isLoading,
    isConnecting:
      isLoading && LoginFlagService.getRouteToTicketsAfterLoginFlag(),
    error,
    login,
    logout,
    isConnected,
    clearError,
    getUserTicketInfo,
    createOrder,
    handleOrderCompleted,
    setGlabalError,
  };

  return (
    <UserContext.Provider value={providerValues}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider };
