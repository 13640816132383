import { Routes, Route, useLocation } from "react-router-dom";
import { HomePage } from "./pages/home";
import { MyTicketsPage } from "./pages/my-tickets";
import { RouteNames } from "./consts/route-names";
import { useEffect } from "react";
import env from "react-dotenv";
import { EVENT_MODAL_ID } from "./consts/consts";
import { useUserData } from "./hooks/use-user-data";
import { PageNotFound } from "./components/page-not-found";
import { ErrorWrapper } from "./components/error-modal/error-modal";
import { RwCookieConsent } from "./components/rw-cookie-consent/rw-cookie-consent";

const App = () => {
  const location = useLocation();
  const routeNameValues = Object.values(RouteNames);
  const isValidPath = routeNameValues.includes(location.pathname as RouteNames);

  const { handleOrderCompleted } = useUserData();

  useEffect(() => {
    (window as any)?.EBWidgets.createWidget({
      widgetType: "checkout",
      eventId: env.EVENTBRITE_EVENT_ID,
      modal: true,
      modalTriggerElementId: EVENT_MODAL_ID,
      onOrderComplete: handleOrderCompleted,
    });
  }, []);

  if (!isValidPath) {
    return (
      <Routes>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    );
  }

  return (
    <ErrorWrapper>
      <>
        <RwCookieConsent />

        <Routes>
          <Route path={RouteNames.home} element={<HomePage />} />
          <Route path={RouteNames.myTickets} element={<MyTicketsPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </>
    </ErrorWrapper>
  );
};

export default App;
