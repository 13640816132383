import { ReactNode } from "react";
import "./base-modal.css";

type Props = {
  onCancelClick: () => void;
  title: string;
  children: ReactNode;
  hideClose: boolean;
};
const BaseModal = ({ title, onCancelClick, hideClose, children }: Props) => {
  return (
    <div className="base-modal-container">
      <div className="base-modal">
        <div className="title-row">
          <p>{title}</p>
          {!hideClose && (
            <button className="close-button" onClick={onCancelClick} />
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export { BaseModal };
