import XImage from "../../../assets/images/X.png";
import Discord from "../../../assets/images/Discord.png";
import Facebook from "../../../assets/images/Facebook.png";
import Instagram from "../../../assets/images/Instagram.png";

import EighthTopBg from "../../../assets/images/eighthTopBg.png";
import EighthBg from "../../../assets/images/eighthBg.png";
import EighthBgH5 from "../../../assets/images/eighthBg_h5.png";

import "./community-section.css";
import {
  discordUrl,
  facebookUrl,
  instagramUrl,
  twitterUrl,
} from "../../../consts/consts";

const CommunitySection = () => {
  return (
    <div className="community-section">
      <div className="eighthBg position-relative">
        <img className="w-100 eighthTopBg" src={EighthTopBg} alt="" />
        <img
          className="h-100 w-100 eighthBgImg eighthBg_web"
          src={EighthBg}
          alt=""
        />
        <img
          className="h-100 w-100 eighthBgImg eighthBg_h5"
          src={EighthBgH5}
          alt=""
        />
      </div>
      <div className="content">
        <div className="container">
          <p
            className="p1 col-md-8 col-12"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            JOIN LIGHTCYCLE COMMUNITY
          </p>
          <p
            className="p2 col-md-8 col-12"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            Dive into the LightCycle world, where you'll be able to connect,
            share and shape the digital space with others in our community.
          </p>
          <div className="links" data-aos="fade-right" data-aos-duration="1200">
            <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
              <img src={XImage} alt="" />
            </a>
            <a href={discordUrl} target="_blank" rel="noopener noreferrer">
              <img src={Discord} alt="" />
            </a>
            <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
              <img src={Facebook} alt="" />
            </a>
            <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
              <img src={Instagram} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CommunitySection };
