import { ApiError } from "../models/api_error";
import { Order } from "../models/order";
import { Result } from "../models/result";
import { User } from "../models/user";
import { RemoteDataRepository } from "../repository/remote-data-repository";

class RemoteManagementUseCase {
  static loginUser = async (): Promise<Result<void, ApiError>> => {
    const result = await RemoteDataRepository.loginUser();
    return result;
  };

  static getUser = async (): Promise<Result<User, ApiError>> => {
    const result = await RemoteDataRepository.getUserTicketInfo();
    return result;
  };

  static createOrder = async (
    orderId: string
  ): Promise<Result<Order, ApiError>> => {
    const result = await RemoteDataRepository.createOrder(orderId);
    return result;
  };

  static transferTicket = async (
    ticketId: string,
    emailTo: string
  ): Promise<Result<void, ApiError>> => {
    const result = await RemoteDataRepository.transferTicket(ticketId, emailTo);
    return result;
  };

  static claimTicket = async (
    ticketId: string
  ): Promise<Result<void, ApiError>> => {
    const result = await RemoteDataRepository.claimTicket(ticketId);
    return result;
  };
}

export { RemoteManagementUseCase };
