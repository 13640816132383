import "./partner_card.css";

type Props = {
  smallImage: any;
  largeImage: any;
};
const PartnerCard = ({ smallImage, largeImage }: Props) => {
  return (
    <div
      className="parLogo d-flex justify-content-center align-items-center"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <img className="parIcon" src={smallImage} alt="" />
      <img className="parIcon_h5" src={largeImage} alt="" />
    </div>
  );
};
export { PartnerCard };
